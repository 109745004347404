body {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: #4E4E4E;
  -webkit-font-smoothing: subpixel-antialiased
}

img {
  max-width: 100%;
}
.tail-spin-loader {
  cursor: pointer;
  filter: brightness(1.2); /* You can adjust the brightness to make it slightly brighter on hover */
  color: orange; /* Change to the desired color */
}
h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 3rem;
}
.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #e99816;
   box-shadow: none; 
}
h2 {
  font-size: 40px;
  color: #43423E;
  font-weight: 300;
}

h4 {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--black, #43423E);
}

p {
  line-height: 1.75rem;
  color: #4E4E4E;
}

strong {
  color: #4b4b4b !important;
}


body .btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #E7A83E;
  --bs-btn-border-color: #E7A83E;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #E7A83E;
  --bs-btn-hover-border-color: #E7A83E;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #E7A83E;
  --bs-btn-active-border-color: #E7A83E;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #E7A83E;
  --bs-btn-disabled-border-color: #E7A83E;
}

.swal2-cancel {
  color: #4E4E4E !important;
  border: 1px solid #4E4E4E !important;
}

button.swal2-cancel:hover {
  background: #4E4E4E !important;
  border: 1px solid #4E4E4E !important;
}

.btn[type="button"],
.btn,
.custom-btn {
  border: 1px solid #E7A83E;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  outline: none;
  position: relative;
  color: #E7A83E;
  font-weight: 400;
  border-radius: 3.125rem;
  padding: 0.5rem 1.875rem;
  margin: 0 auto;
  transition: all 0.5s;
  text-decoration: none;
  background-color: transparent;
  letter-spacing: 1px;
  font-size: 16px;
}

.btn-check:checked+.btn,
body .btn.active,
body .btn.show,
body .btn:first-child:active {
  background-color: none;
  border: 1px solid #E7A83E;
  color: #E7A83E;
  opacity: 1;
}

.swal2-cancel:hover,
.swal2-confirm:hover {
  border: 1px solid #E7A83E;
  background: #E7A83E !important;
  color: #fff !important
}

.form-control {
  outline: none;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #dee2e6;
}

.close-btn {
  position: absolute;
  right: 0.75rem;
  top: 0.875rem;
  font-size: 1.5rem;
  background: #e99714;
  width: 1.375rem;
  height: 1.375rem;
  text-align: center;
  color: #fff;
  border: none;
  border-radius: 4px;
}

.close-btn svg {
  width: 0.555em;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.custom-btn:focus {
  border: 1px solid #E7A83E
}

.custom-btn:hover,
.btn:hover {
  background-color: #E7A83E;
  color: #fff;
  border: 1px solid transparent
}

/* input[type="text"], input[type="password"]{
   background: #F5F5F5;
 } */
secondary .login-btn {
  margin-left: 1.875rem;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1200px;
}

strong {
  color: #212529;
  font-weight: 600;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background-color: #fff;
  border: 1px solid #fff
}

.swal2-modal button:focus {
  box-shadow: none !important;
}


/*Header*/

.sticky-header {
  position: fixed;
  width: 100%;
}

.sticky-header.active img {
  width: 7.813rem;
}
.ant-spin-dot-item {
  background-color: orange !important;
}
.invalid-feedback,
.error-message {
  font-weight: 500;
}
.swal2-container{
  z-index:99999;
}
.error-message {
  color: #dc3545;
  font-size: 15px;
  margin-top: 0.5rem;
  margin-bottom: 0;
}
.fade.shopify-modal {
  z-index: 9999;
}
.warning-btn {
  margin-left: 10px !important;
}

.internet {
  width: 100%;
  height: 100%;
}

div#root {
  height: 100%;
}
.banner-hide{padding-top: 8rem}

.banner-show-modal {
  min-height: 767px;
}

.ant-modal-root{
  z-index:99999;
  position:relative;
}

.ebayModalImport{
  z-index:99999;
}
.fade.modal-backdrop.show {
  z-index: 9999;
}
.api-response {
  background: #e7a83e33;
  padding: 20px;
  border-radius: 20px;
  margin-top: 20px;
}
.api-response1 p {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
}
.api-response1 {
  margin-bottom: 15px;
}
.api-response ul li {
  font-size: 14px;
  font-weight: 500;
}
.headers-csv-import {
  background: #e7a83e33;
  padding: 20px;
  border-radius: 20px;
}
.headers-csv-import .header-dropdown {
  width: 80%;
  margin: 10px;
}

.profile-submit button {
  display: flex;
  margin: 0;
}